import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

// Component
import { Blog } from '../components'

const IndexPost = props => {

  return (
    <React.Fragment>
        <div className="row product-main">
            { 
                props.data.data.allShopifyArticle.edges.map( ( blog, index ) => (
                    <Blog 
                        to = { `/blogs/${ blog.node.handle }` } 
                        className = "Catalogue__item col-sm-12 col-md-6 col-by-4"
                        key = { index }
                        title = { blog.node.title }
                        image = { blog.node.image.src }
                        date = { blog.node.publishedAt.replace( /T.*/ , '' ) }
                    />
                ))
            }
        </div>
    </React.Fragment>
  )
}

const IndexPage = data => {

  return (
    <Layout
      title = { 'Blog' }
    >
        <div className="container">
            <div className="text-center mt-5">
                <h1>Blogs</h1>
            </div>
            <IndexPost data = { data } />  
        </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
    query BlogQuery {
        allShopifyArticle {
            edges {
              node {
                content
                handle
                title
                publishedAt
                image {
                  src
                }
              }
            }
        }
    }
`
